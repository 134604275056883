import React from "react"

import Header from "../components/Header"
import ContentImage from "../components/ContentImage"
import Footer from "../components/Footer"
import GalleryImage from "../components/GalleryImage"
import Text from "../components/Text"
import Services from "../components/Services"
import Values from "../components/Values"
import Opportunities from "../components/Opportunities"
import { OgTags } from "../components/OpenGraph";

const banner = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  noImage: true,
  title: "Play a major role in one of the fastest growing tech companies in the Philippines",
  buttons: [
      {
      type: 'normal',
      text: "We're  hiring",
      link: '/careers/#opportunitiesTitle',
      // beacon: true
      }
  ]
}

const galleryimages = [
  {
    pics: [
      {
        set: [
          {
            name: 'fs pic 1',
            img: require('../assets/images/heart_team.png')
          }, {
            name: 'fs pic 2',
            img: require('../assets/images/office.jpg')
          }
        ]
      },
      {
        set: [
          {
            name: 'fs pic 4',
            img: require('../assets/images/fs-img-4.png')
          }, {
            name: 'fs pic 3',
            img: require('../assets/images/game_room.jpg')
          }
        ]
      }
    ]
  },
  {
    pics: [
      {
        set: [
          {
            name: 'fs pic 5',
            img: require('../assets/images/peng_jk_mau.png')
          }, {
            name: 'fs pic 6',
            img: require('../assets/images/dance.png')
          }
        ]
      },
      {
        set: [
          {
            name: 'fs pic 7',
            img: require('../assets/images/fshq_team_with_bootstrap.png')
          }
        ]
      }
    ]
  },
  {
    pics: [
      {
        set: [
          {
            name: 'fs pic 8',
            img: require('../assets/images/poster.jpg')
          }, {
            name: 'fs pic 9',
            img: require('../assets/images/sticky_notes.jpg')
          }
        ]
      },
      {
        set: [
          {
            name: 'fs pic 10',
            img: require('../assets/images/pool.jpg')
          }, {
            name: 'fs pic 11',
            img: require('../assets/images/rastaman.png')
          }
        ]
      }
    ]
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/benefits-img1.png'),
    desc: 'We provide great compensation secured in a competitive salary ladder, complete mandatory government benefits, 13th month pay, generous vacation leaves, full HMO plan, life insurance and retirement plan for loyal employees.'
  }, {
    image: require('../assets/images/benefits-img2.png'),
    desc: 'Hybrid (office + anywhere) & Flexible working hours — to allow our team to manage their family, personal, and other life needs and to choose to work anytime they feel freshest.'
  }, {
    image: require('../assets/images/benefits-img3.png'),
    desc: 'The office has a game station, a ping-pong table, pool tables, and a laid-back atmosphere with surround music.'
  }, {
    image: require('../assets/images/benefits-img4.png'),
    desc: 'Weekly company games, quarterly thanksgiving, and sports fest — because we believe that in working hard we must also play hard.'
  }, {
    image: require('../assets/images/benefits-img5.png'),
    desc: 'Free coffee and snacks available while you work.'
  }, {
    image: require('../assets/images/benefits-img6.png'),
    desc: 'Development opportunities so you can be better with what you do.'
  },{
    image: require('../assets/images/benefits-img7.png'),
    desc: 'You will be surrounded with team-oriented colleagues and managers. Our team has built a culture of unity to keep each other motivated and a collaborative environment to accomplish successful projects.'
  },{
    image: require('../assets/images/benefits-img8.png'),
    desc: "Exciting challenges and projects are always up for you to have an opportunity to choose what modern technology you would like to use."
  },{
    image: require('../assets/images/benefits-img9.png'),
    desc: "Fullstack HQ is located in the heart of Angeles City surrounded by shops, banks, malls, offices, and a good amount of historical landmarks."
  }
]

const textInfo= [
  {
    desc: "Fullstack HQ is an award-winning and fast-growing software development company based in the heritage district of Angeles City. We partner with world-class entrepreneurs and tech companies helping them to increase efficiency & reduce development costs."
  }, {
    desc: "Our company has been awarded an “Honorable Mention” from Awwwards and four awards from CSSDesignAwards. Those awards being Best UI Design, UX Design, Innovation and Special Kudos."
  }, {
    desc: "We can frequently be found speaking at industry events as well as hosting various educational tours and workshops in our office."
  }
]

const valuesInfo= [
  {
    image: require('../assets/images/6h-hardworking.png'),
    name: 'Hardworking'
  }, {
    image: require('../assets/images/6h-helpful.png'),
    name: 'Helpful'
  }, {
    image: require('../assets/images/6h-honest.png'),
    name: 'Honest'
  }, {
    image: require('../assets/images/6h-humble.png'),
    name: 'Humble'
  }, {
    image: require('../assets/images/6h-hungry.png'),
    name: 'Hungry'
  }, {
    image: require('../assets/images/6h-happy.png'),
    name: 'Happy'
  }
]

const opportunitiesInfo= [
  {
    job: 'Frontend Developer'
    // link: '#'
  }, {
    job: 'Backend Developer'
    // link: '#'
  }, {
    job: 'Web Designer'
    // link: '#'
 }, {
    job: 'Product Support Specialist'
  }
]

function Careers() {
  return (
    <>
      <OgTags
        ogTitle="Jobs in Angeles City, Pampanga - Careers Fullstack HQ"
        // ogDescription="Premium WordPress maintenance with unlimited web edits and fixes for only $99 per month. Learn more with your free trial today."
        ogUrl="https://fullstackhq.com/careers"
      />
      <Header />
      <main className="careers initialized-header">
        <ContentImage content={ banner }  />
        <GalleryImage images= { galleryimages } />
        <Text title="About Fullstack HQ"
          text = { textInfo }  />

        <Values
          values= { valuesInfo }
          title="We Live by Our 6H Culture"
          firstDefinition="Our company culture is defined by 6Hs: Hardworking, Helpful, Honest, Humble, Hungry, and Happy. This one-of-a-kind shared set of values help us in accomplishing our mission — to deliver happiness to all our staff, customers, and partners."
          secondDefinition="Fullstack HQ’s goal is for every single member of our team to achieve their greatest potential and to be happy and proud of the work that they do. Our measure of success is the growth and accomplishments of our team. We know that if we get that right, only great things will follow."
        />

        <Services
          heading="Benefits and perks"
          services= { servicesInfo }
        />

        <Opportunities
          title="Current opportunities"
          desc="We're solving the industry's most interesting problem with its brightest talent, at a scale where everyone still feels like family."
          opportunities= { opportunitiesInfo }
        />
      </main>
      <Footer />
    </>
  )
}

export default Careers
